var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('ResearchPageHeader',[_c('ResearchsSearchForm',{attrs:{"getFiltersAction":"getRankingOfProgramsFilters","fields":[
        'MARKET',
        'TARGET',
        'TV_NETWORK_TYPE',
        'CONNECTED_TVS_SUM_TYPE',
        'START_DATE',
        'END_DATE',
        'DECIMAL_PLACES',
      ],"minStartDate":"2022-06-01"},on:{"searchSubmitted":function($event){return _vm.getRankingOfPrograms($event)},"defaultFiltersValuesLoaded":function($event){return _vm.onDefaultFiltersValuesLoaded($event)}}},[(_vm.ranking.length)?_c('ExportButton',{ref:"exportButton",attrs:{"exportAction":"getRankingOfProgramsExcel","fileName":"ranking-de-programas.xlsx","emit-click":true},on:{"click":_vm.exportExcel}}):_vm._e()],1)],1),_c('main',[_c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"ranking-programs"},[(_vm.ranking.length)?_c('DataFormatControlsContainer',{attrs:{"showDataTypeController":false,"defaultDecimalPlaces":_vm.decimalPlaces,"defaultViewersProjectionBaseValue":_vm.viewersProjectionBase.value,"useCardStyle":false},on:{"decimalPlacesChanged":function($event){return _vm.onDecimalPlacesChange($event)},"viewersProjectionBaseChanged":function($event){return _vm.onViewersProjectionBaseChange($event)}}}):_vm._e(),(_vm.ranking.length)?_c('b-table',{staticClass:"mt-2",attrs:{"small":"","responsive":"","bordered":"","head-variant":"light","hover":"","items":_vm.ranking,"fields":_vm.fields}}):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }