
import { Component, Vue } from "vue-property-decorator";
import ResearchPageHeader from "@/components/ResearchPageHeader.vue";
import ResearchsSearchForm, {
  SearchParamsObject,
} from "@/components/ResearchsSearchForm.vue";
import ExportButton from "@/components/ExportToExcelButton.vue";
import DataFormatControlsContainer from "@/components/DataFormatControlsContainer.vue";
import {
  formatNumberToStringWithSymbol,
  ViewersProjectionBaseType,
  numberToBrString,
} from "@/utils/number.util";
import {
  RankedProgramDTO,
  RankingOfProgramsExportParamsDTO,
  RankingOfProgramsSearchParamsDTO,
} from "@/data/dto/ranking-of-programs";
import { TargetBaseType, getTargetBaseTypeByTargetName, generateViewersProjectionLabel } from '@/utils/viewers-projection.util';

@Component({
  components: {
    ResearchPageHeader,
    ResearchsSearchForm,
    DataFormatControlsContainer,
    ExportButton,
  },
})
export default class RankingOfPrograms extends Vue {
  selectedTargetBaseType: TargetBaseType = 'residences';
  decimalPlaces = 0;
  excelHeader = "Ranking de Programas";
  ranking: RankedProgramDTO[] = [] as RankedProgramDTO[];
  currentSearchParams: {
    valueOnly: RankingOfProgramsSearchParamsDTO;
    completeData: Record<string, any>;
  } | null = null;
  viewersProjectionBase: ViewersProjectionBaseType = {
    value: 1000000,
    label: "Milhão",
    baseSymbol: "M",
    decimalPlaces: 1,
  };

  async exportExcel(): Promise<void> {
    const exportButton = this.$refs.exportButton as ExportButton;
    const params = this.exportToExcelSearchParams;
    await exportButton.exportAndDownload(params);
  }

  async getRankingOfPrograms(params: SearchParamsObject): Promise<void> {
    this.ranking = [];

    const searchParams = params.valueOnly as RankingOfProgramsSearchParamsDTO;

    if (params.completeData.target) {
      this.selectedTargetBaseType = getTargetBaseTypeByTargetName(params.completeData.target.label);
    }

    const getRankingOfProgramsResponse = await this.$store.dispatch(
      "getRankingOfPrograms",
      searchParams
    );

    if (!getRankingOfProgramsResponse) {
      this.$store.commit('showAlert', {
        message: 'Não há dados disponíveis para a consulta realizada.',
        type: 'warning',
      });
      return;
    }

    const { ranking } = getRankingOfProgramsResponse;

    this.currentSearchParams = {
      valueOnly: searchParams,
      completeData: params.completeData,
    };
    this.ranking = ranking;
  }

  get fields() {
    return [
      {
        key: "order",
        label: "Ordem",
        sortable: true,
      },
      {
        key: "tvNetworkId",
        label: "Sigla",
        sortable: false,
      },
      {
        key: "tvNetworkName",
        label: "Emissora",
        sortable: false,
        class: "text-left",
      },
      {
        key: "programExhibitionName",
        label: "Programa",
        sortable: false,
        class: "text-left",
      },
      {
        key: "exhibitionQuantity",
        label: "EXIB",
        sortable: false,

        formatter: (value: number): string | number => {
          return this.formattedDecimalPlaces(value, 0);
        },
      },
      {
        key: "totalExhibitionTime",
        label: "MIN",
        sortable: false,

        formatter: (value: number): string | number => {
          return this.formattedDecimalPlaces(value, 0);
        },
      },
      {
        key: "audience",
        label: "AUD%",
        sortable: false,

        formatter: (value: number): string | number => {
          return this.formattedDecimalPlaces(value);
        },
      },
      {
        key: "share",
        label: "SH%",
        sortable: false,

        formatter: (value: number): string | number => {
          return this.formattedDecimalPlaces(value);
        },
      },
      {
        key: "viewersProjection",
        label: this.viewersProjectionLabel,
        sortable: false,

        formatter: (value: number): string => {
          return this.formattedViewersProjection(value);
        },
      },
    ];
  }

  get viewersProjectionLabel(): string {
    return `AUD ${generateViewersProjectionLabel(this.selectedTargetBaseType)}`;
  }

  get exportToExcelSearchParams(): RankingOfProgramsExportParamsDTO | null {
    if (!this.currentSearchParams) return null;
    return {
      ranking: {
        header: this.excelHeader,
        params: this.currentSearchParams.valueOnly,
      },
    } as RankingOfProgramsExportParamsDTO;
  }

  formattedViewersProjection(
    numberOfViewers: number,
    base: ViewersProjectionBaseType = this.viewersProjectionBase
  ): string {
    if (!numberOfViewers) return "-";
    return formatNumberToStringWithSymbol(numberOfViewers, base);
  }

  onDefaultFiltersValuesLoaded(params: SearchParamsObject): void {
    this.decimalPlaces = params.valueOnly.decimalPlaces || 0;
  }

  onViewersProjectionBaseChange(base: ViewersProjectionBaseType): void {
    this.viewersProjectionBase = base;
  }

  formattedDecimalPlaces(
    n: number,
    decimalPlaces = this.decimalPlaces
  ): number | string {
    if (!n) return "-";
    return numberToBrString(n, decimalPlaces);
  }

  onDecimalPlacesChange(decimalPlaces: number): void {
    this.decimalPlaces = decimalPlaces;
  }
}
